import React, { Component, PropTypes } from 'react';

export default class NotFoundHandler extends Component {

  render() {
    return (
      <h1>Sorry, couldn't find that.</h1>
    );
  }
};
